import classNames from 'classnames';
import React from 'react';

import BlockAnimation from '../components/animations/block-animation';
import Markdown from '../components/markdown';

class BlockText extends React.Component {
  render = () => {
    const { backgroundColor } = this.props;
    if (!backgroundColor) return this.renderText();

    return (
      <div className="text-block__wrapper" style={{ backgroundColor }}>
        {this.renderText()}
      </div>
    );
  };

  renderText = () => {
    const { text, customClass, textColor } = this.props;

    const textBlockClasses = classNames({
      'text-block': true,
      'block-content': true,
      [`text-block--${customClass}`]: !!customClass,
    });

    return (
      <div className={textBlockClasses} style={{ color: textColor || null }}>
        {text && (
          <Markdown markdown={text.childMarkdownRemark.html} isChapter={true}>
            {(parsed) => <BlockAnimation className="text-block__contents">{parsed}</BlockAnimation>}
          </Markdown>
        )}
      </div>
    );
  };
}

export default BlockText;
