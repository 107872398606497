import classNames from 'classnames';
import React from 'react';

import blockConductor from './block-conductor';

const BlockGroup = (props) => {
  const { theme, blocks } = props;

  const blockMarkup = blocks.map((block, i) => blockConductor(block, i));

  return <section className="block-group block-content">{blockMarkup}</section>;
};

export default BlockGroup;
