import loadable from '@loadable/component';
import React from 'react';

const CONTENT_MAPPING = {
  mercedesbenzTypoViewer: loadable(() => import('./block-mercedesbenz-typo-viewer')),
  mercedesbenzIconViewer: loadable(() => import('./block-mercedesbenz-icon-viewer')),
};

const BlockComponentEmbed = ({ sourcePath }) => {
  const Component = CONTENT_MAPPING[sourcePath];

  if (!Component) {
    return null;
  }

  return <Component />;
};

export default BlockComponentEmbed;
