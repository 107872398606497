import classNames from 'classnames';
import React from 'react';

import Image from '../components/image';
import Markdown from '../components/markdown';

const BlockFlag = (props) => {
  const { media, text, reverse } = props;

  const flagClasses = classNames({
    'block-content': true,
    'flag-block': true,
    'flag-block--reverse': reverse,
  });

  return (
    <div className={flagClasses}>
      {media && (
        <div className="flag-block__media">
          <Image image={media} alt={media.title} />
        </div>
      )}
      {text && (
        <div className="flag-block__text">
          <Markdown markdown={text.childMarkdownRemark.html} />
        </div>
      )}
    </div>
  );
};

export default BlockFlag;
