import React from 'react';

import Markdown from '../components/markdown';

class BlockCustom extends React.Component {
  componentDidMount() {
    // Script tags won't get executed through dangerouslySetInnerHTML
    // Extract and append them properly here

    const extractor = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
    let match;

    while ((match = extractor.exec(this.props.content.childMarkdownRemark.html))) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.text = match[1];
      this.refs.dom.appendChild(script);
    }
  }

  render() {
    const { content } = this.props;

    return (
      <>
        {content && (
          <div className="custom-block block-content" ref="dom">
            <Markdown markdown={content.childMarkdownRemark.html} />
          </div>
        )}
      </>
    );
  }
}
export default BlockCustom;
