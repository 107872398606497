import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import { usePrivacyConsent } from '../hooks/usePrivacy';

const BlockVideo = (props) => {
  const {
    video_src: videoRemoteSrc,
    size_modifier: size,
    videoFile,
    playsInline,
    loop,
    customClass,
    posterVideo,
    backgroundColor = 'white',
  } = props;

  const posterSrc = posterVideo?.file?.url;
  const videoSize = size || 'narrow';
  const videoLocalSrc = videoFile?.file?.url;
  const isLocalVideo = !!videoLocalSrc;
  const canUseContents = usePrivacyConsent('contents');
  const [isPlaying, setIsPlaying] = useState(false);

  const videoEl = useRef(null);

  const blockVidClasses = classNames({
    'block-content': true,
    'video-block': true,
    [`video-block--${videoSize}`]: true,
    [`video-block--${customClass}`]: !!customClass,
    'video-block--inline': playsInline,
    'video-block--no-contents': !canUseContents && !isLocalVideo,
  });

  const onPlayClick = useCallback(() => {
    setIsPlaying(true);
    videoEl.current && videoEl.current.play();
  }, [videoEl]);

  return (
    <div className={blockVidClasses} style={{ backgroundColor }}>
      {!canUseContents && !isLocalVideo ? (
        <a
          className="video-block__link"
          href={videoRemoteSrc || videoLocalSrc}
          target="_blank"
          rel="noopener noreferrer"
        >
          Watch video
        </a>
      ) : (
        <div className="video-block__ratio-container">
          {videoRemoteSrc && (
            <iframe
              className="video-block__iframe"
              src={videoRemoteSrc}
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
          )}

          {videoLocalSrc && (
            <video
              className="video-block__video"
              controls={!playsInline}
              playsInline={playsInline}
              muted={playsInline}
              loop={loop}
              autoPlay={playsInline}
              controlsList="nodownload"
              ref={videoEl}
            >
              <source src={videoLocalSrc} type="video/mp4" />
            </video>
          )}

          {posterSrc && !isPlaying && (
            <div>
              <video
                className="video-block__video video-block__video--poster"
                controls={false}
                playsInline
                muted
                loop
                autoPlay
                controlsList="nodownload"
              >
                <source src={posterSrc} type="video/mp4" />
              </video>
              <button
                className="video-block__play-button"
                onClick={onPlayClick}
                aria-label="Play the video"
              >
                <svg
                  width="126"
                  height="126"
                  viewBox="0 0 126 126"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  role="presentation"
                >
                  <circle opacity="0.3" cx="63" cy="63" r="63" fill="#131313" />
                  <path d="M51 41L89.5 63L51 85V41Z" fill="white" />
                </svg>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BlockVideo;
