import classNames from 'classnames';
import React from 'react';

import Image from '../components/image';
import Link from '../components/link';
import Markdown from '../components/markdown';
import { getLink } from '../urls';

const BlockTopicBanner = (props) => {
  const { teaser_1, image_1, topic_1 } = props;

  const bannerItemClasses = classNames({
    'banner__item': true,
    'banner__item--with-image': !!image_1,
  });

  return (
    <div className="banner block-content">
      <div className="banner__inner">
        <div>
          <Image
            asBackground
            tag="div"
            className={bannerItemClasses}
            image={image_1}
            style={{
              backgroundSize: '10em',
              backgroundPosition: 'bottom left',
            }}
          >
            {teaser_1 && (
              <p className="banner__content">
                <Markdown markdown={teaser_1.childMarkdownRemark.html} />
              </p>
            )}
            {topic_1 && <Link className="banner__link" link={getLink(props)} />}
          </Image>
        </div>
      </div>
    </div>
  );
};

export default BlockTopicBanner;
