import React from 'react';

import { resolve } from '../urls';

import Avatar from './avatar';
import Link from './link';

const ContactPerson = (props) => {
  const { person } = props;

  const firstName = person.shortName
    ? person.shortName
    : person.name.split(' ').slice(0, -1).join(' ');

  return (
    <div className="contact-person" itemScope itemType="http://schema.org/Person">
      {person.active_employee ? (
        <Link
          to={resolve('people:detail', { peopleSlug: person.slug })}
          className="contact-person__avatar"
        >
          {person.avatar && <Avatar avatar={person.avatar} />}
        </Link>
      ) : (
        <div className="contact-person__avatar">
          {person.avatar && <Avatar avatar={person.avatar} />}
        </div>
      )}

      <div className="contact-person__details">
        <div className="contact-person__name-container">
          <span className="contact-person__name" itemProp="name">
            {person.name}
          </span>
          {person.position && person.office && (
            <span className="contact-person__title">{`${person.position}, ${person.office.city}`}</span>
          )}
        </div>
        {/* <span className='contact-person__office'>, {person.office.city}</span> */}
        {person.active_employee && person.email && (
          <a href={`mailto:${person.email}`} className="contact-person__contact-link">
            {`Email ${firstName}`}
          </a>
        )}
      </div>
    </div>
  );
};
export default ContactPerson;
