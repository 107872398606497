import React from 'react';

import Image from './image';

const Avatar = (props) => {
  const { avatar } = props;

  return (
    <div className="avatar avatar--medium">
      {avatar && (
        <Image className="avatar__img" image={avatar} alt={avatar.title} itemProp="image" />
      )}
    </div>
  );
};
export default Avatar;
