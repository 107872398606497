import React from 'react';

import ContactPerson from './contact-person';

const ContactsList = (props) => {
  const { contacts } = props;
  return (
    <div className="contacts-list">
      {contacts &&
        contacts.map((person, i) => (
          <div className="contacts-list__person" key={i}>
            <ContactPerson person={person} />
          </div>
        ))}
    </div>
  );
};
export default ContactsList;
