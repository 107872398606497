import classNames from 'classnames';
import React from 'react';

const BlockThemeWrapper = (props) => {
  const { theme } = props;

  const blockThemeClasses = classNames({
    'block-content': true,
    'block-theme-wrapper': true,
    [`block-theme-wrapper--${theme}`]: true,
  });

  return <div className={blockThemeClasses}>{props.children}</div>;
};

export default BlockThemeWrapper;
