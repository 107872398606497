import PropTypes from 'prop-types';
import React from 'react';

import BlockComponentEmbed from './block-component-embed';
import BlockCustom from './block-custom';
import BlockFlag from './block-flag';
import BlockGroup from './block-group';
import BlockImages from './block-images';
import BlockNewsletterSignup from './block-newsletter-signup';
import BlockQuote from './block-quote';
import BlockStatementCollage from './block-statement-collage';
import BlockText from './block-text';
import BlockThemeWrapper from './block-theme-wrapper';
import BlockTopicBanner from './block-topic-banner';
import BlockVideo from './block-video';

// Each case is a Contentful Content Type. Content Types are located in Contentful for each Section model.
const blockConductor = (props, key) => {
  switch (props.__typename) {
    case 'ContentfulBlockGroup':
      if (props.theme) {
        return (
          <BlockThemeWrapper key={key} theme={props.theme}>
            <BlockGroup key={key} {...props} />
          </BlockThemeWrapper>
        );
      }
      return <BlockGroup key={key} {...props} />;

    case 'ContentfulBlockCustom':
      return <BlockCustom key={key} {...props} />;

    case 'ContentfulBlockComponentEmbed':
      return (
        <>
          <BlockComponentEmbed key={key} {...props} />
        </>
      );

    case 'ContentfulBlockFlag':
      return <BlockFlag key={key} {...props} />;

    case 'ContentfulBlockImageS':
      return <BlockImages key={key} {...props} />;

    case 'ContentfulBlockNewsletterSignup':
      if (props.theme) {
        return (
          <BlockThemeWrapper key={key} theme={props.theme}>
            <BlockNewsletterSignup key={key} {...props} />
          </BlockThemeWrapper>
        );
      }
      return <BlockNewsletterSignup key={key} {...props} />;

    case 'ContentfulBlockQuote':
      if (props.theme) {
        return (
          <BlockThemeWrapper key={key} theme={props.theme}>
            <BlockQuote key={key} {...props} />
          </BlockThemeWrapper>
        );
      }
      return <BlockQuote key={key} {...props} />;

    case 'ContentfulBlockStatementCollage':
      return <BlockStatementCollage key={key} {...props} />;

    case 'ContentfulBlockText':
      return <BlockText key={key} {...props} />;

    case 'ContentfulBlockTopicBanner':
      return <BlockTopicBanner key={key} {...props} />;

    case 'ContentfulBlockVideo':
      return <BlockVideo key={key} {...props} />;

    // // Used on old site for curated
    // // featured projects on homepage
    // case 'ContentfulBlockFeaturedProjects':
    //   return <BlockFeaturedProjects key={key} {...props} />;

    // // Service Page blocks
    // case 'ContentfulBlockNextReads':
    //   return <BlockNextReads key={key} {...props} />;

    // case 'ContentfulBlockServiceList':
    //   return <BlockServiceList key={key} {...props} />;

    default:
      return null;
  }
};

export default blockConductor;

// blockConductor.propTypes = {
//   typename: PropTypes.string,
// };
