import classNames from 'classnames';
import { graphql } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import ContactsList from '../components/contacts-list';
import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import SectionCTA from '../sections/section-cta';
import SectionOpener from '../sections/section-opener/section-opener';
import SectionRelatedReading from '../sections/section-related-reading';
import { buildLink, resolve } from '../urls';

class SingleServicePage extends Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { contentfulServices: page } = data;

    let renderedBlocks;
    if (page.body_blocks) {
      renderedBlocks = page.body_blocks.map((block, i) => blockConductor(block, i));
    }

    const pageWrapClasses = classNames({
      'page': true,
      'page--service': true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO
            title={page.serviceName}
            description={page.serviceTeaserText?.serviceTeaserText}
            shareImage={getSrc(page.serviceImage)}
            metadata={page.metadata}
          />

          <SectionOpener openerType="service">
            <div className="service__content-flex-wrap">
              <div className="service__header-content-wrap">
                <Header
                  heading={page.serviceName}
                  label={pageContext.pageType === 'service' ? 'Service' : ''}
                />

                {page.serviceBodyText && (
                  <Markdown markdown={page.serviceBodyText.childMarkdownRemark.html} />
                )}
              </div>
            </div>
            <div className="service__img-flex-wrap">
              {page.serviceImage && (
                <div className="section-opener-service__img-wrap">
                  <Image
                    className="section-opener-service__img"
                    image={page.serviceImage}
                    alt={page.serviceImage.title}
                  />
                </div>
              )}
            </div>
          </SectionOpener>

          <div className="service-detail__body-container">
            {/* Add class names and styling for Quote callouts. able to be flexible and acommodate 1-3 */}
            <div className="service-detail__quote-person-wrap">
              {page.quoteCallouts && (
                <div className="service-detail__quote-container">
                  {page.quoteCallouts.map((quote, i) => (
                    <div className="service-detail__quote" key={i}>
                      <p className="label" />
                      <h2>{quote.quote.quote}</h2>
                      {quote.source && <p>{quote.source.source}</p>}
                    </div>
                  ))}
                  <h2 />
                </div>
              )}

              {page.contact_persons && (
                <div className="content-footer__item content-footer__item--contacts">
                  <div className="content-footer__item-heading">Want to know more?</div>
                  <ContactsList contacts={page.contact_persons} />
                </div>
              )}
            </div>
          </div>

          {renderedBlocks && <>{renderedBlocks}</>}

          {page.relatedCaseStudies && (
            <SectionRelatedReading
              label="Case Studies"
              heading="How we put this into practice"
              reading={page.relatedCaseStudies}
              tall
            />
          )}

          {page.relatedWhitepaper && (
            <SectionCTA
              link={buildLink(
                'Download whitepaper',
                resolve('pages:detail', {
                  pageSlug: page.relatedWhitepaper.slug
                    ? `/services/${page.slug}/whitepaper/`
                    : null,
                })
              )}
              heading={
                page.relatedWhitepaper.teaserTitle ? page.relatedWhitepaper.teaserTitle : null
              }
              label="Get the whitepaper"
              text={page.relatedWhitepaper.teaserText ? page.relatedWhitepaper.teaserText : null}
              buttonText="Download whitepaper"
              buttonSlug={page.relatedWhitepaper.slug ? `/services/${page.slug}/whitepaper/` : null}
              image={page.relatedWhitepaper.mainImage ? page.relatedWhitepaper.mainImage : null}
              isTileImage
            />
          )}

          {page.relatedInsights && (
            <SectionRelatedReading
              label="Related Reading"
              reading={page.relatedInsights}
              darkMode
            />
          )}
        </Layout>
      </div>
    );
  }
}

export default SingleServicePage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulServices(slug: { eq: $slug }) {
      serviceName
      slug
      serviceActionTitle
      serviceImage {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 500)
      }
      serviceTeaserText {
        serviceTeaserText
      }
      serviceBodyText {
        childMarkdownRemark {
          html
        }
      }
      ...ServicesPageBlocksFragment
      quoteCallouts {
        quote {
          quote
        }
        source {
          source
        }
      }
      contact_persons {
        name
        shortName
        active_employee
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 200, aspectRatio: 1)
        }
        position
        slug
        email
        office {
          city
        }
      }
      relatedCaseStudies {
        __typename
        title
        slug
        subtitle
        main_image {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 500)
        }
        tile_image_regular {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 500)
        }
        tile_image_tall {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 500)
        }
      }
      relatedInsights {
        __typename
        title
        slug
        post_category
        teaser {
          childMarkdownRemark {
            html
          }
        }
        main_image {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 400)
        }
      }
      relatedWhitepaper {
        teaserTitle
        teaserText {
          childMarkdownRemark {
            html
          }
        }
        slug
        mainImage {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 500)
        }
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

SingleServicePage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
