import classNames from 'classnames';
import React from 'react';

import Image from '../components/image';
import Link from '../components/link';
import Markdown from '../components/markdown';
import { getLink } from '../urls';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const SectionCTA = (props) => {
  const { link, heading, label, text, buttonText, buttonSlug, image, isTileImage, extended } =
    props;

  const ctaClasses = classNames({
    'section': true,
    'section-cta': true,
    'section-cta--is-background': !isTileImage,
    'section-cta--extended': extended,
  });
  const isTile = isTileImage || false;

  const headingDelay = label ? DELAY_OFFSET : 0;
  const textDelay = headingDelay + (heading ? DELAY_OFFSET : 0);
  const buttonDelay = textDelay + (text ? DELAY_OFFSET : 0);

  return (
    <div className={ctaClasses}>
      {isTile ? (
        <div className="section-cta__img-wrap">
          {image && <Image className="section-cta__img" image={image} alt={image.title} />}
        </div>
      ) : (
        <Image
          asBackground
          tag="div"
          className="section-cta__img-wrap section-cta__img-wrap--is-background"
          image={image}
        />
      )}
      <GroupAnimation className="section-cta__content-wrap">
        {label && (
          <GroupedTextAnimation as="p" className="label">
            {label}
          </GroupedTextAnimation>
        )}
        {heading && (
          <GroupedTextAnimation as="h2" delay={headingDelay}>
            {heading}
          </GroupedTextAnimation>
        )}
        {text && (
          <GroupedTextAnimation delay={textDelay} className="section-cta__text">
            <Markdown markdown={text.childMarkdownRemark.html} />
          </GroupedTextAnimation>
        )}
        {buttonText && buttonSlug && (
          <GroupedBlockAnimation delay={buttonDelay} className="section-cta__button">
            <Link link={link || getLink(props)} className="button" />
          </GroupedBlockAnimation>
        )}
      </GroupAnimation>
    </div>
  );
};

export default SectionCTA;
